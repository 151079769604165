import Constants from 'Constants'

const MarketplaceMixin = {
    methods: {
        checkRequiredUserInfos: async function() {
            const url = Constants.MARKETPLACE_CHECK_USER_INFOS

			const result = await this.$request.request_get_api("MarketplaceMixin::checkRequiredUserInfos", url)
			.catch(error => {
				console.error("MarketplaceMixin::checkRequiredUserInfos => ERROR", error)
			})

			return result ? result : null
        },

        saveRequiredUserInfos: async function(params) {
        	
            const url = Constants.MARKETPLACE_UPDATE_USER_INFOS
			const result = await this.$request.request_post_api("MarketplaceMixin::saveRequiredUserInfos", url, params, false)
			.catch(error => {
				console.error("MarketplaceMixin::saveRequiredUserInfos => ERROR", error)
			})

			return result ? result : null
        },

        getPaymentMethod: async function(only_with_valid_mandate = false) {
        	
            const url = Constants.MARKETPLACE_PAYMENT_METHOD + only_with_valid_mandate
			const result = await this.$request.request_get_api("MarketplaceMixin::getPaymentMethod", url)
			.catch(error => {
				console.error("MarketplaceMixin::getPaymentMethod => ERROR", error)
			})

			if(result.code_retour == "BP" || result.code_retour == "PM") {
				return null
			}
			else {
				return result ? result : null
			}

			// return result ? result.retour : null
        },

        createMandate: async function(bankaccount_id) {
        	
        	let params = {
        		bankaccount_id: bankaccount_id
        	}
            const url = Constants.MARKETPLACE_CREATE_MANDATE
			const result = await this.$request.request_post_api("MarketplaceMixin::createMandate", url, params, false)
			.catch(error => {
				console.error("MarketplaceMixin::createMandate => ERROR", error)
			})

			if(result.code_retour == "BP" || result.code_retour == "PM") {
				this.failureToast(result.message)
				return null
			}
			else {
				return result ? result : null
			}
        },

        cancelMandate: async function(mandate_id) {
        	
        	let params = {
        		mandate_id: mandate_id
        	}
            const url = Constants.MARKETPLACE_CANCEL_MANDATE
			const result = await this.$request.request_post_api("MarketplaceMixin::cancelMandate", url, params, false)
			.catch(error => {
				console.error("MarketplaceMixin::cancelMandate => ERROR", error)
			})

			if(result.code_retour == "BP" || result.code_retour == "PM") {
				this.failureToast(result.message)
				return null
			}
			else {
				return result ? result : null
			}
        },

        savePaymentMethod: async function(params){

            const url = Constants.MARKETPLACE_ADD_PAYMENT_METHOD
			const result = await this.$request.request_post_api("MarketplaceMixin::savePaymentMethod", url, params, false)
			.catch(error => {
				console.error("MarketplaceMixin::savePaymentMethod => ERROR", error)
			})

			if(result.code_retour == "BP" || result.code_retour == "PM") {
				this.failureToast(result.message)
				return null
			}
			else {
				return result ? result : null
			}

        },

        markToPaymentDuedates: async function(duedates, account){

        	let params = {
        		duedate_id: duedates,
        		bankaccount_id: account,
        	}

            const url = Constants.MARKETPLACE_SET_PAYMENT_DUEDATE
			const result = await this.$request.request_post_api("MarketplaceMixin::markToPaymentDuedates", url, params, false)
			.catch(error => {
				console.error("MarketplaceMixin::markToPaymentDuedates => ERROR", error)
			})

			if(result.code_retour == "BP" || result.code_retour == "PM") {
				this.failureToast(result.message)
				return null
			}
			else {
				return result ? result : null
			}

        },

        getMarketplaceType: async function(){

            const url = Constants.MARKETPLACE_GET_TYPE
			const result = await this.$request.request_get_api("MarketplaceMixin::getMarketplaceType", url)
			.catch(error => {
				console.error("MarketplaceMixin::getMarketplaceType => ERROR", error)
			})

			if(result.code_retour == "BP" || result.code_retour == "PM") {
				this.failureToast(result.message)
				return null
			}
			else {
				return result ? result : null
			}

        },
        getMarketplaceTypeNotArea: async function(){

            const url = Constants.MARKETPLACE_GET_TYPE_NOTAREA + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("MarketplaceMixin::getMarketplaceTypeNotArea", url)
			.catch(error => {
				console.error("MarketplaceMixin::getMarketplaceTypeNotArea => ERROR", error)
			})

			if(result.code_retour == "BP" || result.code_retour == "PM") {
				this.failureToast(result.message)
				return null
			}
			else {
				return result ? result.retour : null
			}

        },
        redirectBoutique: async function(duedates){

        	let params = {
        		duedate_id: duedates
        	}

            const url = Constants.MARKETPLACE_GET_BOUTIQUE_URL
			const result = await this.$request.request_post_api("MarketplaceMixin::redirectBoutique", url, params, false, {autoCatch: false, withDetails: true})
			.catch(error => {
				console.error("MarketplaceMixin::redirectBoutique => ERROR", error)
				this.failureToast(error.data.retour)
				return null
			})

			if(result == null) {
				return null
			}

			if(result.code_retour == "BP" || result.code_retour == "PM" || result.code_retour == "CURRENCY") {
				this.failureToast(result.message)
				return null
			}
			else {
				return result ? result : null
			}

        },
		redirectBoutiqueDetails: async function(invoicedetails_ids, amount){

        	let params = {
        		invoicedetails_id: invoicedetails_ids,
        		amount: amount,
        	}

            const url = Constants.MARKETPLACE_GET_BOUTIQUE_DETAILS_URL
			const result = await this.$request.request_post_api("MarketplaceMixin::redirectBoutiqueDetails", url, params, false)
			.catch(error => {
				console.error("MarketplaceMixin::redirectBoutiqueDetails => ERROR", error)
			})

			if(result.code_retour == "BP" || result.code_retour == "PM") {
				this.failureToast(result.message)
				return null
			}
			else {
				return result ? result : null
			}
        },
		redirectBoutiqueContract: async function(contract_id){

        	let params = {
        		contract_ids: contract_id,
        	}

            const url = Constants.MARKETPLACE_GET_BOUTIQUE_CONTRACT_URL
			const result = await this.$request.request_post_api("MarketplaceMixin::redirectBoutiqueContract", url, params, false)
			.catch(error => {
				console.error("MarketplaceMixin::redirectBoutiqueContract => ERROR", error)
			})

			if(["BP", "PM", "NOR", "CURRENCY"].includes(result.code_retour)) {
				this.failureToast(result.message)
				return null
			}
			else {
				return result ? result : null
			}
        }
    }
}

export default MarketplaceMixin
import Constants from 'Constants'
import { EventBus } from 'EventBus'
import Common from '@/assets/js/common.js'

const InvoiceMixin = {
    methods: {
        getAddressedInvoicesToPay: async function() {
            const url = Constants.INVOICES_ADRESSED_TOPAY + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getAddressedInvoicesToPay", url)
			.catch(error => {
				console.error("InvoiceMixin::getAddressedInvoicesToPay => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

        getAddressedInvoicesSold: async function() {
            const url = Constants.INVOICES_ADRESSED_PAID + "?licence_key="+Constants.USER_LICENCE_KEY
            
			const result = await this.$request.request_get_api("InvoiceMixin::getAddressedInvoicesSold", url)
			.catch(error => {
				console.error("InvoiceMixin::getAddressedInvoicesSold => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

        getAddressedPayments: async function() {
            const url = Constants.PAIEMENTS_ADRESSED + "?licence_key="+Constants.USER_LICENCE_KEY
            
			const result = await this.$request.request_get_api("InvoiceMixin::getAddressedInvoicesSold", url)
			.catch(error => {
				console.error("InvoiceMixin::getAddressedInvoicesSold => ERROR", error)
				return null
			})

			return result ? result.retour : null
        },

        getAddressedPdfInvoice: async function(invoices) {
            invoices.forEach(invoice => {
                const url = this.constructRoute(Constants.INVOICES_PDF_ADDRESSED, {
                    invoice_id: invoice.invoice_id
                }) + "?licence_key="+Constants.USER_LICENCE_KEY

				this.$request.request_get_api("InvoiceMixin::pdfInvoice", url)
                    .then(res => {
                        const filename = invoice.invoice_num || invoice.invoice_date
				        Common.base64ToPdf(res, `${filename}.pdf`)
                        EventBus.$emit("TableAction::stopSpin")
                    })
                    .catch(error => {
                        console.error("InvoiceMixin::getAddressedPdfInvoice => ERROR", error)
                        return null
                    })
            })
		},


		// DEVIS
		getAllQuotation: async function() {
            const url = Constants.QUOTATIONS_ADRESSED + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("InvoiceMixin::getAllQuotation", url)
			.catch(error => {
				console.error("InvoiceMixin::getAllQuotation => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},

		getQuotationPdf: async function(quotation_id, quotation_num, base64 = false) {
			const url = this.constructRoute(Constants.QUOTATIONS_PDF_ADDRESSED, {quotation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("QuotationMixins::getQuotationPdf", url)
			if(result) {
				if(base64) {
					return result
				}
				Common.base64ToPdf(result, quotation_num + ".pdf")
			}
			return null
		},

		loadInvoiceContract: async function(contract_id) {
			const url = `${this.constructRoute(Constants.CONTRACT_INVOICES_URL, {contract_id})}?licence_key=${Constants.USER_LICENCE_KEY}`
			const result = await this.$request.request_get_api("InvoiceMixin::loadInvoiceContract", url)

			if(result) return result.retour
			return null
		},
    }
}

export default InvoiceMixin
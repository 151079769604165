<template>

    <div class="box">
    	<form action="">
    		<div class="form-group">
    			<label for="type_paiement">{{ $t('marketplace.type_paiement') }}</label>
    			<select v-model="form.type" class="form-control" id="type_paiement">
    				<option :value="type.type" v-for="type in payment_type" :key="type.type">{{ type.type }}</option>
    			</select>
    		</div>

    		<template v-if="form.type == 'SEPA'">
    			<div class="form-group">
    				<label for="libelle">{{ $t('marketplace.libelle') }}</label>
    				<input type="text" class="form-control" v-model="form.libelle"  :class="{ 'is-invalid': errors && errors.indexOf('libelle') > -1 }">
    			</div>

    			<div class="form-group">
    				<label for="iban">{{ $t('marketplace.iban') }}</label>
    				<input type="text" class="form-control" v-model="form.iban"  :class="{ 'is-invalid': errors && errors.indexOf('iban') > -1 }">
    			</div>

    			<div class="form-group">
    				<label for="bic">{{ $t('marketplace.bic') }}</label>
    				<input type="text" class="form-control" v-model="form.bic"  :class="{ 'is-invalid': errors && errors.indexOf('bic') > -1 }">
    			</div>
    		</template>

    		<div class="form-group">
    			<button class="btn btn-primary" @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t('global.ajouter') }}</button>
    		</div>
    	</form>
    </div>
</template>


<script type="text/javascript">
    import Invoice from '@/mixins/Invoice.js'
    import Marketplace from '@/mixins/Marketplace.js'
    import Navigation from '@/mixins/Navigation.js'

	export default {
        name: "PaymentsMethodsList",
        mixins:[Marketplace, Invoice, Navigation],
		data () {
			return {
                processing: false,
                payment_type: [
                	{
                		type: "SEPA",
                	},
                	// {
                	// 	type: "CB"
                	// }
                ],
                form: {
                	type: "SEPA",
                	libelle: null,
                	iban: null,
                	bic: null,
                },
                errors: [],
                events_tab: {
                }
			}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            async init_component() {

            },

            async checkForm(){

                if(!this.processing)
                {
                    this.processing = true
                    this.errors = []

                    if(this.form.type == "SEPA")
                    {
                        if(!this.form.libelle)
                        {
                            this.errors.push("libelle")
                        }

                        if(!this.form.iban)
                        {
                            this.errors.push("iban")
                        }
                    }

                    if(this.errors.length > 0)
                    {
                        this.processing = false
                        return false
                    }

                    let result = null
                    result = await this.savePaymentMethod(this.form)
                    if(result)
                    {
                        this.successToast('marketplace.toast_payment_method_add_success')
                        this.$router.push({ name: 'paymentMethodList' })
                    }

                    this.processing = false
                }
            },
            
        },
        
        computed: {
            
        },

		components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}
</script>
